.besys-menu-icon {
  height: 20px;
  width: 30px;
}

.side-drawer-logo {
  padding: 20px 0;
}

.side-drawer-menu-list {
  padding: 25px 0 !important;
  background-color: var(--transparent) !important;
}

.side-drawer-icons > svg {
  color: var(--white);
  background: var(--transparent);
  font-size: 3rem !important;
  border-radius: 0;
}

.side-drawer-icons > img {
  padding: 12px;
}

.sideDrawer-footer-block {
  position: absolute;
  bottom: 20px;
}

.sideDrawer-footer-block p {
  margin: 0;
  padding: 0 30px;
  color: var(--white);
}
