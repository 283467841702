$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-login-register {
  // background: url("../../assets/images/LoginBG.jpg");
  background: url("../../assets/images/Login-BgImg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height: 111vh;
}

.litigation--project-block {
  display: grid;
  justify-content: flex-start;
  align-items: center;
}

/*Left section of Login Register Screen*/
.litigation--project {
  background: var(--petrol-green);
  opacity: 90%;
  margin: 20px;
  border-width: 0px;
  border-left: 6px solid;
  border-image: linear-gradient(
      to bottom,
      var(--light-green),
      var(--light-green--2)
    )
    1 100%;
  min-width: 365px;
  /*transform: translate(-50%, 75%);
    -ms-transform: translate(-50%, 75%);
    -webkit-transform: translate(-50%, 75%);
    position: absolute;
    top: 0%;
    left: 20%;*/
  padding: 20px;
}

.litigation-project-name {
  font-family: "SiemensSans-Black";
  font-size: 1.5rem;
  color: var(--dark-blue);
  margin: 0;
  /*background: -webkit-linear-gradient(90deg, var(--master-blue), var(--blue-green), var(--master-blue), var(--blue-green), var(--master-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
}

.litigation--project-description {
  font-size: 1.125rem;
  display: block;
  margin: 15px 0;
}

/*Right Section of Login Register Screen*/
.litigation-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}

.litigation-logo {
  padding: 60px 10px;
}

.rtc-intra-logo {
  height: 1rem;
  margin: 0 10px;
}

.official-logo {
  height: 1.4rem;
  margin-top: 5px;
}

.tabs-additional-padding {
  padding: 0 100px;
}

.rtc-button--login,
.rtc-button--register {
  width: 50%;
  transition: 0.5s;
}

.rtc-button--login-icon,
.rtc-button--register-icon {
  font-size: 0.75rem !important;
  border-radius: 50%;
  background: transparent !important;
}

/*Mobile*/
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .official-logo {
    margin-right: 20px;
    padding-top: 5px;
  }

  .rtc-button--login,
  .rtc-button--register {
    width: 60%;
  }

  .tabs-additional-padding {
    padding: 0;
  }
}

/*Mid-mobile*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  /*.litigation-mobileContainer {
        display: flex;
        flex-direction: column-reverse;
    }*/

  .tabs-additional-padding {
    padding: 0px;
  }
}

/*Small Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .official-logo {
    height: 1.1rem;
  }

  /*.litigation-mobileContainer {
        display: flex;
        flex-direction: column-reverse;
    }*/

  .litigation--project-description {
    top: -10%;
    left: 25%;
  }

  .tabs-additional-padding {
    padding: 0px;
  }
}

/*Large Tablet*/
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .litigation--project-description {
    top: -5%;
    left: 25%;
  }
}

.pt-lr-block {
  padding: 16px 40px;
  margin-top: 120px;
  // background-color: rgb(255, 255, 255, 0.8);
  background: var(--petrol-green);
  opacity: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

.litigation-mobileContainer {
  padding: 40px;
  justify-content: center;
  //flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    padding: 0px 20px;
  }
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.m-0 {
  margin: 0;
}

.myid-login-btn-block {
  margin: 10px 0 0;
}

.supplier-login-btn-block {
  margin: 10px 0 0;
}

.pt-divider {
  margin: 10px;
}

.dashed {
  border-width: 2px;
}
